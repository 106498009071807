<template>
  <div class="fill-height">
    <router-view/>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>

</style>